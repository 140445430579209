import React from 'react';

function Crocify() {
  return (
    <div> <div style={{ position: 'relative', paddingBottom: '0%', height: '0' }}>
    <iframe
      src="https://miko.blue/laser/"
      title="Crocify"
      frameBorder="0"
      allowFullScreen
    
      className='ifram'
    ></iframe>
  
  </div>
  <br/>  <br/>
  </div>
   
  );
}

export default Crocify;
