import React from 'react'
import { Grid } from '@mui/material'
import somg from './song.mp3'
function Desktopthree() {
  return (
    <div className='footer'>


        
<Grid container>
        
         <Grid item md={12} xs={12} lg={12} className='centerall '>
       
        
         <a href="#" class="matrix-text text-sm text-white hover:text-gray-300 px-4 a footerfont"  >Copyright © $DBUFF DARK BUFFET </a>
         </Grid>
          </Grid>

    </div>
  )
}

export default Desktopthree